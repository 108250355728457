window.Vue = require('vue').default;

const currentDomain = window.location.hostname;

if (currentDomain === 'euro24.news') {
    // Регистрируем компоненты для example.com
    Vue.component('example-component', require("./components/Example").default);
    Vue.component('subscribe-component', require("./components/Subscribe").default);
    Vue.component('submodal-component', require("./components/SubModal").default);

} else {
    // Регистрируем компоненты для another-example.com
    Vue.component('submodal-component-italy', require("./components/SubModalItaly").default);
    Vue.component('subscribe-component-italy', require("./components/SubscribeItaly").default);
}
Vue.component('pay-component', require("./components/Pay").default);
Vue.component('order-component', require("./components/Order").default);

const app = new Vue({
    el: "#vuejs",
})
app.$mount()
