<template>
    <div v-if="!!show">
        <transition name="modal-fade">
            <div class="modal-backdrop">
                <div class="modal"
                     role="dialog"
                     aria-labelledby="modalTitle"
                     aria-describedby="modalDescription"
                >
                    <header
                        class="modal-header"
                        id="modalTitle"
                    >
                        <slot name="header">
                            <img class="header__logo-img mr-2" width="67" height="47" alt="Логотип новостного портала 127.0.0.1" src="/assets/default/images/logo.png">
                            Вы хотели бы получать уведомления о последних новостях и событиях?
                        </slot>
                    </header>
                    <footer class="modal-footer">
                        <slot name="footer">
                            <button
                                type="button"
                                class="btn-no"
                                @click="close"
                                aria-label="Close modal"
                            >
                                НЕ СЕЙЧАС
                            </button>

                            <button
                                type="button"
                                class="btn-green"
                                @click="startFCM"
                            >
                                РАЗРЕШИТЬ
                            </button>
                        </slot>
                    </footer>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {getMessaging, getToken, isSupported, onMessage} from "firebase/messaging";
import {initializeApp} from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyADxdl2apmKwBd9MSceEIPX7Tf4OkpzwM0",
    authDomain: "czech-5c06a.firebaseapp.com",
    projectId: "czech-5c06a",
    storageBucket: "czech-5c06a.appspot.com",
    messagingSenderId: "1086704232253",
    appId: "1:1086704232253:web:6ee17bb3dca3be21e3c754",
    measurementId: "G-CT43X20MF0"
};

const app = initializeApp(firebaseConfig)
onMessage(getMessaging(app), function (payload) {
    const title = payload.notification.title;
    const options = {
        body: payload.notification.body,
        icon: payload.notification.icon,
    };
    new Notification(title, options);
})

export default {
    name: "SubModal",
    data(){
      return {
          show:false
      }
    },
    methods: {
        close() {
            localStorage.show = 0;
           this.show = false
        },
        startFCM() {
            if (!!isSupported()) {
                getToken(getMessaging(app), {vapidKey: 'BGqgwmjjZZU_2osF_vlbHDZy_KZXvXChfnhEGHicMdgqkOrqraW_yonN7-MG_h0P2CYPsSgs0oL5xyD2JZ-H0gQ'}).then((currentToken) => {
                    if (currentToken) {
                        localStorage.pushi = JSON.stringify({token: currentToken});
                        this.pushi = {token: currentToken}
                        localStorage.show = 0;
                        this.show = false
                        fetch('/api/subscribe-email', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({token: currentToken})
                        }).then(response => response.json())
                            .then(data => console.log(data))
                            .catch(e => console.log(e))
                    } else {
                        localStorage.show = 0;
                        this.show = false
                        console.log('No registration token available. Request permission to generate one.');
                    }
                }).catch((err) => {
                    localStorage.show = 0;
                    this.show = false
                    console.log('An error occurred while retrieving token. ', err);
                });
            }
        }
    },
    mounted() {
        this.show = !!localStorage.show ? JSON.parse(localStorage.show) === 0 && false : true;
    }
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 9999;
}

.modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-width: 509px;
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
}
.btn-no {
    color: #134184;
    background: #ffffff;
    /*border: 1px solid #4AAE9B;*/
    border-radius: 2px;
    height: 39px;
    cursor: pointer;
}
.btn-green {
    margin-left: 5px;
    color: white;
    background: #134184;
    border: 1px solid #134184;
    border-radius: 2px;
    height: 39px;
    cursor: pointer;
}
</style>
