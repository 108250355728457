<template>
    <div class="footer-subscribe-content">
        <div class="footer-subscribe-title">Подпишитесь, чтобы получать лучшие новости недели</div>
        <div v-if="!!pushi">
            <form class="footer-subscribe-form">
                <input v-model="pushi.email" :readonly=true class="footer-subscribe-input merriweather" type="email"
                       placeholder="Ваш Email">
                <svg v-on:click="resetSub"
                     class="footer-subscribe-submit d-flex align-items-center justify-content-center" width="53px"
                     height="53px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M29 16V9c0-1-1-2-2-2H5C4 7 3 8 3 9v14c0 1 1 2 2 2h11"/>
                    <path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M3 9l11.862 8.212a2 2 0 002.276 0L29 9"/>
                    <circle cx="24" cy="23" r="5" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"/>
                    <path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M21 26l6-6"/>
                </svg>
            </form>
        </div>
        <div v-else>
            <div class="footer-subscribe-form">
                <input v-on:keyup.enter="checkForm" class="footer-subscribe-input merriweather" type="email" placeholder="Ваш Email" v-model="email">
                <b v-if="errors.length" style="color: red">{{ errors[0] }}</b>
                <a v-on:click='checkForm'
                   class="footer-subscribe-submit d-flex align-items-center justify-content-center">
                    <svg width="63" height="12" viewBox="0 0 63 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M63 6L53 0.226497V11.7735L63 6ZM0 7H54V5H0V7Z" fill="white"/>
                    </svg>
                </a>
            </div>
            <div class="footer-subscribe-text merriweather">
                Отправляя свое электронное письмо, вы соглашаетесь с нашими условиями и <a target="_blank" href="/other/privacy">политикой
                конфиденциальности</a>. Вы можете отказаться в любое время
            </div>
        </div>
    </div>
</template>

<script>
import {initializeApp} from 'firebase/app';
import {getMessaging, onMessage, getToken, isSupported} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyADxdl2apmKwBd9MSceEIPX7Tf4OkpzwM0",
    authDomain: "czech-5c06a.firebaseapp.com",
    projectId: "czech-5c06a",
    storageBucket: "czech-5c06a.appspot.com",
    messagingSenderId: "1086704232253",
    appId: "1:1086704232253:web:6ee17bb3dca3be21e3c754",
    measurementId: "G-CT43X20MF0"
};

const app = initializeApp(firebaseConfig)
onMessage(getMessaging(app), function (payload) {
    const title = payload.notification.title;
    const options = {
        body: payload.notification.body,
        icon: payload.notification.icon,
    };
    new Notification(title, options);
})

export default {
    name: "Subscribe",
    data() {
        return {
            email: '',
            errors: [],
            pushi: null
        }
    },

    methods: {
        checkForm: function (e) {
            console.log(1)
            this.errors = [];
            if (!this.email) {
                this.errors.push('Укажите электронную почту.');
            } else if (!this.validEmail(this.email)) {
                this.errors.push('Укажите корректный адрес электронной почты.');
            }
            if (!this.errors.length) {
                return this.startFCM();
            }
            console.log(this.errors)
            e.preventDefault();
            e.stopPropagation();
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        startFCM() {
            if (!!isSupported()) {
                getToken(getMessaging(app), {vapidKey: 'BGqgwmjjZZU_2osF_vlbHDZy_KZXvXChfnhEGHicMdgqkOrqraW_yonN7-MG_h0P2CYPsSgs0oL5xyD2JZ-H0gQ'}).then((currentToken) => {
                    if (currentToken) {
                        console.log(currentToken)
                        localStorage.pushi = JSON.stringify({token: currentToken, email: this.email});
                        this.pushi = {token: currentToken, email: this.email}
                        fetch('/api/subscribe-email', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({token: currentToken, email: this.email})
                        }).then(response => response.json())
                            .then(data => console.log(data))
                            .catch(e => console.log(e))
                        // Send the token to your server and update the UI if necessary
                        // ...
                    } else {
                        // Show permission request UI
                        console.log('No registration token available. Request permission to generate one.');
                        // ...
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    // ...
                });
            }
        },
        resetSub() {
            fetch('/api/unsubscribe-email', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.pushi)
            }).then(response => response.json())
                .then(data => console.log(data))
                .catch(e => console.log(e))
            localStorage.removeItem('pushi');
            this.pushi = null;
        }
    },
    async mounted() {
        this.pushi = !!localStorage.pushi ? JSON.parse(localStorage.pushi) : null;
    }
}
</script>


