<template>
    <div v-if="!!survey.title" class="survey-card d-flex flex-column">
        <a class="article-cat d-flex align-items-center">Опрос</a>
        <div class="survey-title">{{ survey.title }}</div>
        <div></div>
        <div v-if="!!result" class="survey-form d-flex flex-column">
            <div v-for="pool in survey.pools" :key="pool.id">
                <label class="variant d-flex align-items-center">
                    {{ pool.text }} - голосов ({{pool.result + (pool.id === picked && 1)}})
                </label>
            </div>
        </div>
        <div v-else class="survey-form d-flex flex-column">
            <div v-for="pool in survey.pools" :key="pool.id">
                <label class="variant d-flex align-items-center">
                    <input type="radio" name="variant" :value="pool.id" v-model="picked">
                    {{ pool.text }}
                </label>
            </div>
            <button :disabled="!picked" class="survey-card-submit" v-on:click=handlerSetSurvey>Голосовать</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            survey: {},
            picked: '',
            result:false
        }
    },
    methods:{
        handlerSetSurvey(){
            this.result = true;
            fetch('/api/vote', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pool: this.picked,
                })
            }).then(result => result.json()).then(data => {
                if(data.data.length > 0){
                    this.survey = data.data[0]
                }
            }).catch(e => console.log(e))
        }
    },
    mounted() {
        fetch('/api/survey', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(result => result.json()).then(data => {
            if(data.data.length > 0){
                this.survey = data.data[0]
            }
        }).catch(e => console.log(e))
    }
}
</script>
