<template>
    <div class="news-block">
        <div class="container">
            <div class="block_head d-flex align-items-center justify-content-between">
                <h1 class="main-title">Информация о заказе</h1>
            </div>
            <div v-if="!order.id">
                Заказ не найден
            </div>
            <div v-if="order.id">
                <div class="reklama-tables fira">
                    <div class="reklama-table">
                        <div class="reklama-table-head d-flex align-items-center justify-content-center text-center">
                            Заказ № {{ orderId }}
                        </div>
                        <div class="reklama-table-content">
                            <table>
                                <thead>
                                <tr>
                                    <th v-for="header in table.headers" :key="header">{{ header }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in table.items" :key="+item.id">
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.price }}€
                                        {{ item.device !== 'additionally' ? ` x ${order.amount_days} д. = ${(Number(item.price * order.amount_days).toFixed(2))}€` : '' }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="selected-items-summary">
                    <div class="m-2">
                        <strong>Počet dnů / Количество дней: {{ order.amount_days }}</strong>
                    </div>
                    <div class="m-2">
                        <strong>Celková cena / Общая стоимость: {{ order.amount }}€</strong>
                    </div>
                    <div class="m-2">
                        <strong>Stát platby / Статус оплаты: {{ order.transaction_status }}</strong>
                    </div>
                    <div class="m-2">
                        <strong>Stav objednávky / Статус заказа: {{ order.order_status }}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        initialOrderId: String
    },
    data() {
        return {
            orderId: this.initialOrderId,
            table: {
                headers: ['Название', 'Цена'],
                items: [], // Данные для таблицы ПК
            },
            order: {}
        };
    },
    methods: {
        fetchData() {
            fetch('/api/pay/order-info/' + this.orderId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.table.items = data.data.pay_product
                    this.order = data.data
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
    },
    mounted() {
        this.fetchData();
    },
    computed: {},
};
</script>

<style scoped>

</style>

