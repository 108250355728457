<template>
    <div class="news-block">
        <div class="container">
            <div class="block_head d-flex align-items-center justify-content-between">
                <h1 class="main-title">Для рекламодателей</h1>
            </div>

            <div v-if="payInfo === 1" class="reklama-tables fira">
                <div class="reklama-table" v-for="(table, index) in tables" :key="index">
                    <div class="reklama-table-head d-flex align-items-center justify-content-center text-center">
                        {{ table.title }}
                    </div>
                    <div :class="['reklama-table-content', { 'additional-class': table.addCustomRow }]">
                        <table>
                            <thead>
                            <tr>
                                <th v-for="header in table.headers" :key="header">{{ header }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in table.items" :key="item.id" v-if="!table.addCustomRow">
                                <td>{{ item.type }}</td>
                                <td>{{ item.size }}</td>
                                <td>{{ item.exposures_day }}</td>
                                <td>{{ item.exposures_week }}</td>
                                <td>{{ item.price }}</td>
                                <td>
                                    <div class="custom-checkbox">
                                        <input type="checkbox" :id="'checkbox' + item.id" :value="item.id"
                                               v-model="selectedItems">
                                        <label :for="'checkbox' + item.id"></label>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="item in table.items" :key="+item.id" v-if="table.addCustomRow">
                                <td>{{ item.type }}</td>
                                <td>{{ item.price }}</td>
                                <td>
                                    <div class="custom-checkbox">
                                        <input type="checkbox" :id="'checkbox' + item.id" :value="+item.id"
                                               v-model="selectedItems">
                                        <label :for="'checkbox'+item.id"></label>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="table.addCustomRow && isScreenLarge">
                                <td><textarea class="add-service-text" type="text" v-model="customItem.type"
                                              placeholder="Договорная услуга"></textarea></td>
                                <td><input type="number" v-model="customItem.price" placeholder="Цена"></td>
                                <td>
                                    <button class="add-button" @click="addCustomItem">Добавить</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="selected-items-summary">

                <h2>Выбранные услуги</h2>
                <ul>
                    <li v-for="item in selectedItemsDetails" :key="item.id">
                        {{ item.type }}: {{ item.price }} € {{
                            tables[2].items.map(el => el.id).includes(item.id) ? '' : '* ' + days + ' день = ' + (Number(item.price * days).toFixed(2)) + ' €'
                        }}
                    </li>
                </ul>
                <div class="form-group">
                    <label for="days">Počet dnů / Количество дней:</label>
                    <input minlength="1" :class="'required'" type="number" id="days" v-model.number="days" min="1">
                    <span v-if="dayError" class="error">{{ dayError }}</span>
                </div>
                <div>
                    <strong>Celková cena / Общая стоимость: {{ Number(totalPrice).toFixed(2) }} €</strong>
                </div>
                <div class="error-info" v-if="addProductError">
                    <span class="error">{{ addProductError }}</span>
                </div>

                <button v-if="payInfo === 1" type="button" @click="changePage('add')">Dokončit / Оформить</button>

            </div>

            <div v-if="payInfo === 1">
                <span @click="changeShowSearch">Для поиска заказа нажмите <span class="text-color-th">сюда</span></span>
            </div>
            <div v-if="payInfo === 1 && !!showSearch " class="selected-items-summary">
                <h2>Найти заказ</h2>
                <div class="form-group">
                    <label for="days">Číslo objednávky / Номер заказа:</label>
                    <input type="text" id="orderId" v-model="orderId">
                    <span v-if="orderError" class="error">{{ orderError }}</span>
                </div>
                <button type="button" @click="searchOrder">Hledání objednávky / Поиск заказа</button>
            </div>

            <div v-if="payInfo === 2" class="pay-form">

                <form @submit.prevent="handleSubmit">

                    <div class="form-group" v-for="field in formFields" :key="field.id">
                        <label :for="field.id" :class="field.required ? 'required' : ''">{{ field.label }}</label>
                        <input
                            :type="field.type"
                            :id="field.id"
                            v-model="formData[field.id]"
                            :required="field.required"
                            @input="handleInput(field.id, $event)">
                        <span v-if="field.id === 'name' && nameError" class="error">{{ nameError }}</span>
                        <span v-if="field.id === 'phone' && phoneError" class="error">{{ phoneError }}</span>
                    </div>

                    <div class="form-group to-firm custom-checkbox">
                        <input type="checkbox" id="companyCheck" v-model="formData.companyCheck">
                        <label for="companyCheck">Objednávám na firmu / Заказ оформляю на фирму</label>
                    </div>
                    <div v-if="formData.companyCheck">
                        <div class="form-group" v-for="companyField in companyFields" :key="companyField.id">
                            <label :for="companyField.id"
                                   :class="companyField.required ? 'required' : ''">{{ companyField.label }}</label>
                            <input :type="companyField.type" :id="companyField.id" v-model="formData[companyField.id]"
                                   :required="companyField.required">
                        </div>
                    </div>

                    <div class="form-group to-firm custom-checkbox">
                        <input type="checkbox" id="torg" v-model="formData.torg" name="torg">
                        <label for="torg"><a href="/other/obchodni-podminky" target="_blank">Souhlasím s našimi
                            obchodními podmínkami / Согласен с нашими торговыми условиями</a></label>
                    </div>
                    <div class="form-group to-firm custom-checkbox">
                        <input type="checkbox" id="personal" v-model="formData.personal" name="personal">
                        <label for="personal"><a href="/other/gdpr" target="_blank">Souhlasím se zpracováním osobních
                            údajů / Согласен с обработкой персональных данных</a></label>
                    </div>
                    <div class="error-info" v-if="payError">
                        <span class="error">{{ payError }}</span>
                    </div>
                    <button type="submit">PŘEJDĚTE NA PLATBU / ПЕРЕЙТИ К ОПЛАТЕ</button>
                    <button @click="changePage('min')" type="button">Zpět / Назад</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tables: [
                {
                    title: 'БАННЕРЫ КОМПЬЮТЕРА',
                    headers: ['Тип', 'Размер (px)', 'Экспозиций в день', 'Экспозиций в неделю', 'Цена, €', 'Выбрать'],
                    items: [], // Данные для таблицы ПК
                    addCustomRow: false,
                },
                {
                    title: 'БАННЕРЫ ТЕЛЕФОНА',
                    headers: ['Тип', 'Размер (px)', 'Экспозиций в день', 'Экспозиций в неделю', 'Цена, €', 'Выбрать'],
                    items: [], // Данные для таблицы телефона
                    addCustomRow: false,
                },
                {
                    title: 'ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ',
                    headers: ['Тип', 'Цена, €', 'Выбрать'],
                    items: [], // Данные для дополнительных услуг
                    addCustomRow: true,
                },
            ],
            selectedItems: [],
            customItem: {
                type: '',
                price: '',
                device: 'additionally',
            },
            formFields: [
                {id: 'name', label: 'Jméno a příjmení / Имя и фамилия:', type: 'text', required: true},
                {id: 'email', label: 'E-mail:', type: 'email', required: true},
                {id: 'phone', label: 'Telefon / Телефон:', type: 'text', required: true},
            ],
            companyFields: [
                {id: 'ico', label: 'IČO / CIN / ИНН:', type: 'text', required: true},
                {id: 'dic', label: 'DIČ / VAD / ОГРН:', type: 'text', required: false},
                {id: 'company_name', label: 'Název společnosti / Название фирмы:', type: 'text', required: true},
                {id: 'address', label: 'Adresa / Адрес:', type: 'text', required: true},
                {id: 'city', label: 'Město / Город:', type: 'text', required: true},
                {id: 'postal_code', label: 'PSČ / Индекс:', type: 'text', required: true},
                {id: 'country', label: 'Země / Страна:', type: 'text', required: true},
            ],
            formData: {
                name: '',
                email: '',
                phone: '',
                companyCheck: false,
                ico: '',
                dic: '',
                company_name: '',
                address: '',
                city: '',
                postal_code: '',
                country: '',
                torg: false,
                personal: false
            },
            nameError: '',
            phoneError: '',
            isScreenLarge: window.innerWidth > 1023,
            days: 1,
            dayError: '',
            payError: '',
            payInfo: 1,
            addProductError: '',
            urlPay: '',
            orderId: '',
            orderError: '',
            showSearch:false
        };
    },
    methods: {
        changePage(t) {
            this.dayError = ''
            this.addProductError = ""
            if (this.selectedItems.length === 0) {
                return this.addProductError = 'Vyberte alespoň jeden produkt! / Выберите хоть один продукт!'
            }
            if (this.days >= 1) {
                if (t === 'add') {
                    this.payInfo = this.payInfo + 1
                } else {
                    this.payInfo = this.payInfo - 1
                }
            } else {
                this.dayError = 'Укажите день!'
            }
        },
        checkScreenSize() {
            this.isScreenLarge = window.innerWidth > 1023;
        },
        handleSubmit() {
            if (this.nameError) {
                return;
            }
            if (!this.validatePhoneNumber(this.formData.phone)) {
                this.phoneError = 'Zadejte platné telefonní číslo / Введите корректный номер телефона.';
                return;
            }
            if (!this.formData.torg && !this.formData.personal) {
                return this.payError = 'Согласитесь с нашими торговыми условиями и обработкой персональных данных';
            }
            this.urlPay = '';
            this.phoneError = '';
            this.payError = '';
            // console.log('Form Data:', this.formData);
            // Создаем массив для хранения данных выбранных элементов
            const selectedData = [];

            // Проходимся по таблицам и ищем выбранные элементы
            this.tables.forEach(table => {
                table.items.forEach(item => {
                    if (this.selectedItems.includes(item.id)) {
                        selectedData.push(item);
                    }
                });
            });


            this.phoneError = '';
            // Выводим данные выбранных элементов в консоль
            // console.log('Selected Items Data:', selectedData);

            try {
                const bodyFormData = new FormData();
                bodyFormData.append('days', this.days);
                bodyFormData.append('name', this.formData.name);
                bodyFormData.append('email', this.formData.email);
                bodyFormData.append('phone', this.formData.phone);
                bodyFormData.append('companyCheck', Number(this.formData.companyCheck));

                !!this.formData.ico && bodyFormData.append('ico', this.formData.ico);
                !!this.formData.dic && bodyFormData.append('dic', this.formData.dic);
                !!this.formData.company_name && bodyFormData.append('company_name', this.formData.company_name);
                !!this.formData.address && bodyFormData.append('address', this.formData.address);
                !!this.formData.city && bodyFormData.append('city', this.formData.city);
                !!this.formData.postal_code && bodyFormData.append('postal_code', this.formData.postal_code);
                !!this.formData.country && bodyFormData.append('country', this.formData.country);

                selectedData.forEach((el, index) => {
                    bodyFormData.append(`products[${index}][id]`, el.id);
                    bodyFormData.append(`products[${index}][type]`, el.type);
                    bodyFormData.append(`products[${index}][price]`, el.price);
                    bodyFormData.append(`products[${index}][device]`, el.device);
                })

                fetch('/api/pay/store', {
                    headers: {
                        'Accept': 'application/json'
                    },
                    method: 'POST',
                    body: bodyFormData
                }).then(response => response.json())
                    .then(data => {
                        // console.log(data)
                        window.location.href = data.urlPay;
                    }).catch(e => this.payError = 'Nákup je zatím nemožný / Покупка пока невозможна')
            } catch (e) {
                console.log(e)
            }
        },
        addCustomItem() {
            if (!!this.customItem.type && !!this.customItem.price) {
                this.tables[2].items.push({
                    type: this.customItem.type,
                    price: parseFloat(this.customItem.price).toFixed(2),
                    id: Date.now() + 0.1,
                    device: this.customItem.device,
                });
                this.customItem.type = '';
                this.customItem.price = '';
            }
        },
        fetchData() {
            fetch('/api/pay', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.tables[0].items = data.pc;
                    this.tables[1].items = data.mobile;
                    this.tables[2].items = data.dop;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
        validatePhoneNumber(phone) {
            const regex = /^(\+420)? ?\d{3} ?\d{3} ?\d{3}$/;
            return regex.test(phone);
        },
        formatPhoneNumber(event) {
            let input = event.target.value.replace(/\D/g, '');
            if (input.startsWith('420')) input = input.substring(3);
            let formattedPhone = '+420 ';
            if (input.length > 0) formattedPhone += input.substring(0, 3);
            if (input.length > 3) formattedPhone += ' ' + input.substring(3, 6);
            if (input.length > 6) formattedPhone += ' ' + input.substring(6, 9);
            this.formData.phone = formattedPhone.trim();
            if (input.length === 0) this.formData.phone = '+420 ';
        },
        validateName(value) {
            const namePattern = /^[^\s]+(\s+[^\s]+)+$/; // регулярное выражение для проверки наличия имени и фамилии
            if (!namePattern.test(value)) {
                this.nameError = 'Имя и фамилия должны быть разделены пробелом';
            } else {
                this.nameError = '';
            }
        },
        handleInput(fieldId, event) {
            if (fieldId === 'phone') {
                this.formatPhoneNumber(event);
            }
            if (fieldId === 'name') {
                this.validateName(event.target.value);
            }
        },
        searchOrder() {
            this.orderError = ''
            fetch('/api/pay/order-info/'+this.orderId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    if(response.status !== 200){
                        throw new Error('Такой заказ не найден!');
                    }else{
                        response.json()
                    }
                })
                .then(data => {
                    window.location.href = '/order-info/'+this.orderId;
                })
                .catch(error => {
                    this.orderError = 'Tato objednávka nebyla nalezena / Такой заказ не найден'
                });
        },
        changeShowSearch(){
            this.showSearch = !this.showSearch
        }
    },
    mounted() {
        this.fetchData();
        window.addEventListener('resize', this.checkScreenSize);
        this.checkScreenSize(); // Инициализация при загрузке
    },
    computed: {
        selectedItemsDetails() {
            return this.tables.flatMap(table =>
                table.items.filter(item => this.selectedItems.includes(item.id))
            );
        },
        totalPrice() {
            return this.selectedItemsDetails.reduce((total, item) => {
                if (this.tables[2].items.map(el => el.id).includes(item.id)) {
                    return total + Number(item.price);
                } else {
                    return total + item.price * this.days;
                }

            }, 0);
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    }
};
</script>

<style scoped>
.news-block {
    padding: 20px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.block_head {
    margin-bottom: 20px;
}

.main-title {
    color: #003366;
}

.reklama-tables .reklama-table {
    margin-bottom: 20px;
}

.reklama-table-head {
    background-color: #003366;
    color: white;
    padding: 10px;
    border-radius: 4px 4px 0 0;
}

.reklama-table-content {
    border: 1px solid #003366;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
}

.reklama-table-content table {
    //width: 100%;
    border-collapse: collapse;
}

.reklama-table-content th,
.reklama-table-content td {
    border: 1px solid #003366;
    padding: 8px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #003366;
}

.form-group input[type="number"],
.form-group input[type="text"],
.form-group input[type="email"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group input[type="checkbox"] {
    margin-right: 10px;
}

button[type="submit"] {
    width: 100%;
    background-color: #003366;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button[type="submit"]:hover {
    background-color: #00509e;
}

button[type="button"] {
    margin-top: 10px;
    width: 100%;
    background-color: #003366;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button[type="button"]:hover {
    background-color: #00509e;
}

.hidden {
    display: none;
}

.to-firm {
    display: flex;
    flex-direction: row;
}

/* Основной стиль контейнера для чекбокса */
.custom-checkbox {
    align-items: center;
}

/* Скрытие стандартного чекбокса */
.custom-checkbox input[type="checkbox"] {
    display: none;
}

/* Создание кастомного чекбокса */
.custom-checkbox label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    color: #003366;
}
.text-color-th{
    color: #003366;
    cursor: pointer;
}
/* Стиль кастомного чекбокса (квадрат) */
.custom-checkbox label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 2px solid #003366;
    border-radius: 4px;
    background-color: white;
}

/* Стиль для галочки */
.custom-checkbox input[type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    left: 7px;
    top: 7px;
    width: 10px;
    height: 10px;
    border: solid #003366;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.add-button {
    cursor: pointer;
    background-color: #003366;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
}

.add-service-text {
    width: 90%;
}

.selected-items-summary {
    margin-bottom: 20px;
}

.selected-items-summary h2 {
    color: #003366;
}

.selected-items-summary ul {
    list-style: none;
    padding: 0;
}

.selected-items-summary li {
    background-color: #f9f9f9;
    border: 1px solid #003366;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 5px;
    color: #003366;
}

.error {
    color: red;
    font-size: 14px;
}

.error-info {
    margin: 10px;
    text-align: center;
}
</style>
